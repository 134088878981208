import Link, { LinkProps } from "../components/Link";
import Imagee from "next/image";
import Image from "../components/Image";
import { styled, theme, darkTheme, keyframes, stitchesConfig } from "../lib/styles/stitches.config";
import { authorName, authorSocial, PGPKeyId, role } from "../lib/config/siteMetadata";
import selfieJpg from "../public/static/images/me/pic_cartoon4.jpeg";
import Typed from "react-typed";

const ColorfulLink = ({
  lightColor,
  darkColor,
  css,
  ...rest
}: LinkProps & {
  lightColor: string;
  darkColor: string;
}) => {
  return (
    <Link
      css={{
        color: lightColor,
        ...stitchesConfig.utils.setUnderlineColor({ color: lightColor }),

        [`.${darkTheme} &`]: {
          color: darkColor,
          ...stitchesConfig.utils.setUnderlineColor({ color: darkColor })
        },

        ...css
      }}
      {...rest}
    />
  );
};

const H1 = styled("h1", {
  margin: "0 0 0.5em -1px", // misaligned left margin, super nitpicky
  fontSize: "1.8em",
  fontWeight: 500,
  lineHeight: 1.1,
  color: theme.colors.text,

  "@medium": {
    fontSize: "1.6em"
  }
});

const H2 = styled("h2", {
  margin: "0.5em 0 0.5em -1px", // misaligned left margin, super nitpicky
  fontSize: "1.35em",
  fontWeight: 400,
  lineHeight: 1.4,
  color: theme.colors.text,

  "@medium": {
    fontSize: "1.25em"
  }
});

const H3 = styled("h3", {
  margin: "0.5em 0 0.5em -1px", // misaligned left margin, super nitpicky
  fontSize: "0.97em",
  fontWeight: 300,
  lineHeight: 1.0,
  color: "$text",

  "@medium": {
    fontSize: "0.9"
  }
});

const Paragraph = styled("p", {
  margin: "0.85em 0",
  lineHeight: 1.5,
  color: theme.colors.text,

  "&:last-of-type": {
    marginBottom: 0
  },

  "@medium": {
    fontSize: "0.95em",
    lineHeight: 1.5
  }
});

const Wave = styled("span", {
  display: "inline-block",
  marginLeft: "0.1em",
  fontSize: "1.2em",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${keyframes({
      "0%": { transform: "rotate(0deg)" },
      "5%": { transform: "rotate(14deg)" },
      "10%": { transform: "rotate(-8deg)" },
      "15%": { transform: "rotate(14deg)" },
      "20%": { transform: "rotate(-4deg)" },
      "25%": { transform: "rotate(10deg)" },
      "30%": { transform: "rotate(0deg)" },
      // pause for ~9 out of 10 seconds
      "100%": { transform: "rotate(0deg)" }
    })} 5s ease 1s infinite`,
    transformOrigin: "65% 80%",
    willChange: "transform"
  }
});

const Sup = styled("sup", {
  margin: "0 0.15em",
  fontSize: "0.6em"
});

const PGPKey = styled("code", {
  marginLeft: "0.15em",
  fontSize: "1.1em",
  fontWeight: 500,
  wordSpacing: "-0.4em"
});

const Quiet = styled("span", {
  color: theme.colors.mediumLight
});

const RoundedImage = styled(Image, {
  width: "200px",
  height: "200px",
  border: `1px solid ${theme.colors.light}`,
  borderRadius: "50%",
  //marginLeft: "-10px",

  "@medium": {
    width: "200px",
    height: "200px",
    borderWidth: "2px"
  }
});

const IntroSection = () => {
  const talkAbout = [
    "Infra As Code",
    "Cloud Computing",
    "Cybersecurity",
    "Automation",
    "Terraform",
    "PKI infrastructure",
    "Powershell"
  ];

  return (
    <>
      <section>
        <div
          style={{
            margin: "0px 10px 0 0",
            //margin: "0 auto",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center"
          }}
        >
          <div
            style={{
              margin: "0 auto"
            }}
          >
            <Imagee
              src={selfieJpg}
              alt={`Photo of ${authorName}`}
              width={200}
              height={200}
              quality={100}
              placeholder="empty"
              priority
            />
          </div>

          <div
            style={{
              margin: "0px 10em 0.5em 0px",
              whiteSpace: "nowrap"
            }}
          >
            <H1>
              <b>Hi, I'm Rashid</b> <Wave>👋🏽</Wave>
            </H1>
            <H3>{role} based in 🇸🇪</H3>
          </div>
        </div>
      </section>

      <Paragraph>
        With a passion for{" "}
        <ColorfulLink
          href="https://www.geeksforgeeks.org/introduction-to-scripting-languages/"
          title="Scripting Languages"
          lightColor="#973999"
          darkColor="#db60dd"
          css={{
            // rotated 🪄 emoji on hover
            "&:hover": {
              cursor: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='30' style='font-size:24px'><text y='50%' transform='rotate(-70 0 0) translate(-20, 6)'>🪄</text></svg>") 5 5, auto`
            }
          }}
        >
          scripting
        </ColorfulLink>
        ,{" "}
        <ColorfulLink
          href="https://xkcd.com/1319/"
          title='"Automation" on xkcd'
          lightColor="#ff6200"
          darkColor="#f46c16"
        >
          automation
        </ColorfulLink>
        ,{" "}
        <ColorfulLink
          href="https://www.tutorialspoint.com/sql/sql-database-tuning.htm"
          title="Node.js Official Website"
          lightColor="#6fbc4e"
          darkColor="#84d95f"
        >
          database tuning
        </ColorfulLink>{" "}
        and <Quiet>making the life easier for developers, I think 🤷</Quiet>.
      </Paragraph>

      <Paragraph>
        I write <Link href="/blog">articles</Link> about DevOps and Cybersecurity concepts.
      </Paragraph>
      <Paragraph>
        You can talk to me about{" "}
        <ColorfulLink
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%73%68%69%6C%73%61%72%65%40%67%6D%61%69%6C%2E%63%6F%6D?subject=Discussion&body=I%20was%20on%20your%20site%20and%20was%20wondering%20if%20you..."
          title="Drop an e-mail"
          lightColor="#8892bf"
          darkColor="#a4afe3"
        >
          <Typed
            loop
            typeSpeed={80}
            backSpeed={20}
            strings={talkAbout}
            smartBackspace
            backDelay={1000}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
        </ColorfulLink>
        .
      </Paragraph>

      <Paragraph>When I am not doing IT stuff, I'm travelling, swimming or chilling with the loved ones.</Paragraph>
      <Paragraph>
        Find me on{" "}
        <ColorfulLink
          href={`https://www.linkedin.com/in/${authorSocial.linkedin}`}
          rel="me"
          title={`${authorName} on LinkedIn`}
          lightColor="#0073b1"
          darkColor="#3b9dd2"
        >
          LinkedIn
        </ColorfulLink>{" "}
        and{" "}
        <ColorfulLink
          href={`https://github.com/${authorSocial.github}`}
          rel="me"
          title={`${authorName} on GitHub`}
          lightColor="#8d4eff"
          darkColor="#a379f0"
        >
          GitHub
        </ColorfulLink>
        . <br />
        You can also{" "}
        <ColorfulLink
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%73%68%69%6C%73%61%72%65%40%67%6D%61%69%6C%2E%63%6F%6D?subject=Discussion&body=I%20was%20on%20your%20site%20and%20was%20wondering%20if%20you..."
          title="Send e-mail 📧"
          lightColor="#e40088"
          darkColor="#fd40b1"
          css={{
            // rotated 🪄 emoji on hover
            "&:hover": {
              cursor: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='30' style='font-size:24px'><text y='50%' transform='rotate(-70 0 0) translate(-20, 6)'>🪄</text></svg>") 5 5, auto`
            }
          }}
        >
          write to me{" "}
        </ColorfulLink>
        <Sup>
          <ColorfulLink
            href="/pubkey.asc"
            rel="pgpkey authn"
            title="My Public Key"
            lightColor="#757575"
            darkColor="#959595"
            underline={false}
            openInNewTab
          >
            🔐 <PGPKey>{PGPKeyId}</PGPKey>
          </ColorfulLink>
        </Sup>{" "}
        if you would like to discuss something.
      </Paragraph>
    </>
  );
};

export default IntroSection;
