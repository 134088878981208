import IntroSection from "./IntroSection";

const Index = () => {
  return (
    <>
      <IntroSection />
    </>
  );
};

export default Index;
